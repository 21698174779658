/* NOTE: these input styles are used in text area and text input */
.text-input {
  & [data-field-input] {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--inner-spacing-1);
    border-radius: var(--border-radius-elevation-3);
    background-color: var(--color-bg-neutral-subtle);
    flex: 1;
    max-inline-size: 100%;
    isolation: isolate;
    padding-inline: var(--inner-spacing-2);
    box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle);
  }

  & [data-field-input] :is(input, textarea) {
    border: 0;
    background-color: transparent;
    font-family: inherit;
    flex-grow: 1;
    color: var(--color-fg);
    text-overflow: ellipsis;
    max-inline-size: 100%;
    width: 100%;
    box-sizing: content-box;
    block-size: calc(
      var(--body-line-height) + var(--body-margin-start) +
        var(--body-margin-end)
    );
    padding-block: var(--inner-spacing-3);

    &:autofill,
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
      font-size: initial;
      /** Removing browser's autofill styles */
      -webkit-text-fill-color: var(--color-fg);
      -webkit-box-shadow: 0 0 0 40rem var(--color-bg-neutral-subtle) inset;
    }

    &:focus {
      outline: none;
    }
  }

  & [data-field-input][data-hovered] {
    background-color: var(--color-bg-neutral-subtle-hover);
    box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle-hover);
  }

  /**
  * ----------------------------------------------------------------------------
  * READONLY
  *-----------------------------------------------------------------------------
  */
  & [data-readonly][data-field-input] {
    background-color: transparent;
    box-shadow: none;
    padding-inline: 0;
  }

  /**
  * ----------------------------------------------------------------------------
  * FOCUSSED
  *-----------------------------------------------------------------------------
  */

  /**
  * We use an absolutely positioned pseudo element because
  * we need to make the focus state in read mode a bit wider than the component width
  */

  & [data-field-input][data-focused] {
    background-color: transparent;
    box-shadow: none;
  }

  & [data-field-input][data-focused]:before {
    content: "";
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: 0 0 0 2px var(--color-bd-focus);
    border-radius: var(--border-radius-elevation-3);
    z-index: -1;
  }

  & [data-readonly][data-field-input][data-focused]:before {
    left: calc(-0.5 * var(--inner-spacing-1));
    width: calc(100% + var(--inner-spacing-1));
  }

  /**
  * ----------------------------------------------------------------------------
  * ERROR
  *-----------------------------------------------------------------------------
  */
  & [data-field-input][data-invalid] {
    box-shadow: 0 0 0 1px var(--color-bd-negative);
  }

  & [data-field-input][data-invalid][data-hovered] {
    box-shadow: 0 0 0 1px var(--color-bd-negative-hover);
  }

  /**
  * ----------------------------------------------------------------------------
  * ICON
  *-----------------------------------------------------------------------------
  */
  & [data-icon] {
    color: var(--color-fg-neutral);
  }

  /**
  * ----------------------------------------------------------------------------
  * DESCRIPTION
  *-----------------------------------------------------------------------------
  */
  & [data-field-description-text] {
    color: var(--color-fg-neutral);
  }

  /**
  * ----------------------------------------------------------------------------
  * PLACEHOLDER
  *-----------------------------------------------------------------------------
  */
  & [data-field-input] :is(input, textarea)::placeholder {
    color: var(--color-fg-neutral-subtle);
    opacity: 1;
  }

  & [data-field-input] :is(input, textarea):placeholder-shown {
    text-overflow: ellipsis;
  }

  /**
  * ----------------------------------------------------------------------------
  * DISABLED
  *-----------------------------------------------------------------------------
  */
  & [data-disabled],
  & [data-disabled] :is(input, textarea),
  & [data-disabled] label {
    cursor: not-allowed;
    box-shadow: none;
  }

  /**
  * ----------------------------------------------------------------------------
  * PREFIX AND SUFFIX
  *-----------------------------------------------------------------------------
  */
  & :is([data-field-input-prefix], [data-field-input-suffix]) {
    block-size: 0;
    display: flex;
    align-items: center;
  }

  &
    [data-field-input]:has(textarea)
    :is([data-field-input-prefix], [data-field-input-suffix]) {
    display: none;
  }

  /**
  * ----------------------------------------------------------------------------
  * SIZE
  *-----------------------------------------------------------------------------
  */
  & [data-field-input] input[data-size="small"] {
    padding-block: var(--inner-spacing-2);
  }
}
