@import "../../shared/colors/colors.module.css";

.item {
  display: flex;
  align-items: center;
  padding-inline: var(--inner-spacing-3);
  block-size: var(--sizing-11);
}

.item [data-icon] {
  margin-inline-end: var(--inner-spacing-2);
}

.item [data-chevron] {
  margin-inline-end: 0;
  margin-inline-start: var(--inner-spacing-2);
}

.item:first-of-type {
  border-top-left-radius: var(--border-radius-elevation-3);
  border-top-right-radius: var(--border-radius-elevation-3);
}

.item:last-of-type {
  border-bottom-left-radius: var(--border-radius-elevation-3);
  border-bottom-right-radius: var(--border-radius-elevation-3);
}

.item:not([data-disabled]) {
  cursor: pointer;
}

.item[data-hovered] {
  background-color: var(--color-bg-accent-subtle-hover);
}

.item[data-selected] {
  background-color: var(--color-bg-accent-subtle-active);
}

.item[data-disabled] {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}

.item[data-focus-visible] {
  box-shadow:
    inset 0 0 0 2px var(--color-bg),
    inset 0 0 0 4px var(--color-bd-focus);
}

.separator {
  border-top: var(--border-width-1) solid var(--color-bd);
  padding: 0;
}

.text {
  flex: 1;
}

/* making sure the first and last child are not displayed when they have the data-separator attribute */
.separator:is(:first-child, :last-child) {
  display: none;
}
