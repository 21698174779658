.menu {
  max-height: inherit;
  overflow-y: auto;

  /** If at least one menu item has an icon, we need to add extra padding for items that doesn't have an icon. */
  &:has([data-icon]:not([data-chevron]))
    [role="menuitem"]:not(:has([data-icon])) {
    padding-inline-start: calc(
      var(--icon-size-2) + var(--inner-spacing-3) + var(--inner-spacing-2)
    );
  }
}
